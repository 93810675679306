/* BurgerMenu.css */
.bm-burger-button {
  position: relative;
  width: 36px;
  height: 30px;
  margin-left: 10px; /* Add some margin to separate it from the heading */
  cursor: pointer;
  z-index: 1000; /* Ensure it stays above other elements */
  display: none; /* Hide by default */
}

.bm-burger-bars {
  width: 100%;
  height: 4px;
  background: #373a47;
  margin: 5px 0;
  transition: all 0.3s ease;
}

.bm-cross-button {
  height: 24px;
  width: 24px;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  z-index: 1001;
}

.bm-cross {
  position: absolute;
  width: 24px;
  height: 24px;
  top: 0;
  right: 0;
}

.bm-cross:before,
.bm-cross:after {
  position: absolute;
  left: 11px;
  content: " ";
  height: 24px;
  width: 2px;
  background-color: #bdc3c7;
}

.bm-cross:before {
  transform: rotate(45deg);
}

.bm-cross:after {
  transform: rotate(-45deg);
}

.bm-menu {
  background: #373a47;
  position: fixed;
  top: 0;
  left: 0;
  width: 250px; /* Adjust the width to your preference */
  height: 100%; /* Ensure it covers the full height of the screen */
  transform: translateX(-100%);
  transition: transform 0.3s ease;
  display: flex;
  flex-direction: column;
  padding: 20px;
  box-sizing: border-box;
  z-index: 1000; /* Ensure it stays above other elements */
}

.bm-menu.open {
  transform: translateX(0);
}

.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
  margin-top: 50px; /* Ensure items don't overlap with the cross button */
}

.bm-item {
  display: block;
  color: #d1d1d1;
  margin-bottom: 10px;
  text-decoration: none;
  transition: color 0.2s;
}

.bm-item:hover {
  color: white;
}

.desktop-item {
  color: #333333; /* Darker color for desktop menu items */
}

.desktop-item:hover {
  color: #000000; /* Even darker on hover */
}

.bm-item-select {
  color: #d1d1d1; /* Lighter color for select in burger menu */
}

.desktop-item-select {
  color: #333333; /* Darker color for select in desktop menu */
}

@media (max-width: 768px) {
  .bm-burger-button {
    display: block; /* Show the burger button on small screens */
    margin-right: 10px; /* Add margin between burger button and heading */
  }

  .navbar-left {
    display: flex;
    align-items: center;
    margin-left: 10px; /* Add margin between burger button and heading */
  }

  .desktop-links {
    display: none; /* Hide the desktop links on small screens */
  }

  .mobile-visible {
    display: flex; /* Ensure the logout button and language select are visible on small screens */
  }
}

.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.navbar-left {
  display: flex;
  align-items: center;
}

.navbar-links {
  display: flex;
  align-items: center;
  gap: 20px; /* Add gap between the links */
  margin-left: 20px; /* Add margin between heading and links */
}

.navbar-right {
  display: flex;
  align-items: center;
  gap: 20px; /* Add gap between select and logout button */
}

.navbar-select,
.navbar-logout {
  height: 40px; /* Ensure the height matches */
}
